import cx from 'classnames';
import React from 'react';

import * as style from './specs.module.scss';

const Specs = ({ specs, className }) => (
  <dl className={cx(style.main, className)}>
    {specs.map((spec) => (
      <div key={spec.key} className={cx(style.spec, { [style.isFullLine]: spec.isFullLine })}>
        <dt className={style.key}>{spec.key}</dt>
        <dd className={style.value}>
          {Array.isArray(spec.value)
            ? spec.value.map((val, index) => <p key={index}>{val}</p>)
            : spec.value}
        </dd>
      </div>
    ))}
  </dl>
);

export default Specs;
