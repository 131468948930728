import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import Button from '@/components/common/Button';
import Carousel from '@/components/common/Carousel';
import MissingImage from '@/components/common/Empty/MissingImage';
import Markdown from '@/components/common/Markdown';
import Price from '@/components/common/Price';
import Specs from '@/components/common/Specs';
import Seo from '@/components/seo';
import { arrayFromQueryString } from '@/utils/parsing';
import { categoryParentsFromCategory } from '@/utils/traversing';
import { getLocalOrRemoteImages } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './product.module.scss';

const ProductTemplate = ({ data, pageContext }) => {
  const { data: contextData } = pageContext;
  const { title, shortDescription, description, category, brand, specs, fields, uuid } =
    contextData.product;

  const prices = typeof window !== 'undefined' && window.localStorage.getItem('prices');
  const priceArs = JSON.parse(prices)[uuid];

  const images = getLocalOrRemoteImages(data);
  const firstImageSrc = get(images, '[0].childImageSharp.gatsbyImageData.images.fallback.src');
  const categories = data.allGoogleSpreadsheetCategoryTree.edges.map((e) => e.node);
  const specsArray = arrayFromQueryString(specs);
  const categoryBreadcrumbs = categoryParentsFromCategory(category, categories).map((e) => ({
    url: `/${ROUTE_NAMES.CATEGORIES}/${e.uuid}`,
    name: e.name,
  }));
  const breadcrumbs = [
    {
      url: `/${ROUTE_NAMES.PRODUCTS}`,
      name: 'Productos',
    },
    ...categoryBreadcrumbs,
    {
      name: title,
    },
  ];

  return (
    <>
      <Seo title={title} description={`${shortDescription}`} image={firstImageSrc} />
      <div className={style.main} data-uuid={uuid}>
        <div className={style.topbar}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <article className={style.product}>
          <div className={style.container}>
            <section className={style.gallery}>
              <Carousel className={style.carousel}>
                {images.length
                  ? images.map((image) => (
                      <GatsbyImage
                        alt={title}
                        className={style.image}
                        key={image.path}
                        objectFit="contain"
                        objectPosition="center center"
                        image={image.childImageSharp?.gatsbyImageData}
                      />
                    ))
                  : [<MissingImage key="missingImage" className={style.image} />]}
              </Carousel>
            </section>
            <section className={style.details}>
              <div className={style.header}>
                <Price priceArs={priceArs} className={style.price} />
                <h1 className={style.title}>{title}</h1>
                <p className={style.shortDescription}>{shortDescription}</p>
                <Link
                  to={`/${ROUTE_NAMES.CONTACT}?referencedProducts=${uuid}`}
                  className={style.mainActionLink}
                >
                  <Button isSecondary isBlock>
                    Realizar una consulta
                  </Button>
                </Link>
              </div>
              {description && <Markdown className={style.description} source={description} />}
              {!!specsArray.length && <Specs className={style.specs} specs={specsArray} />}
              <div className={style.categories}>
                <Link className={style.chipLink} to={`/${ROUTE_NAMES.CATEGORIES}/${category}`}>
                  <Button isChip isPrimary>
                    {fields.categoryName}
                  </Button>
                </Link>
                <Link className={style.chipLink} to={`/${ROUTE_NAMES.BRANDS}/${brand}`}>
                  <Button isChip isPrimary>
                    {fields.brandName}
                  </Button>
                </Link>
              </div>
            </section>
          </div>
        </article>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query productMeta($imageDir: String) {
    allFile(filter: { relativeDirectory: { regex: $imageDir } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          relativePath
        }
      }
    }
    allGoogleSpreadsheetCategoryTree {
      edges {
        node {
          uuid
          name
          parentCategory
        }
      }
    }
    allS3Object(filter: { Key: { regex: $imageDir } }) {
      edges {
        node {
          Key
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
export default ProductTemplate;
