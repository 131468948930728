// extracted by mini-css-extract-plugin
export var carousel = "product-module--carousel--c1932";
export var categories = "product-module--categories--0ffb4";
export var chipLink = "product-module--chipLink--4b254";
export var container = "product-module--container--14cb8";
export var description = "product-module--description--9491f";
export var details = "product-module--details--8d776";
export var gallery = "product-module--gallery--acaa9";
export var header = "product-module--header--06a2d";
export var image = "product-module--image--dbe4b";
export var main = "product-module--main--a0e9c";
export var mainActionLink = "product-module--mainActionLink--a8ad9";
export var price = "product-module--price--ab6cd";
export var product = "product-module--product--8be4c";
export var shortDescription = "product-module--shortDescription--7813d";
export var specs = "product-module--specs--b9e68";
export var title = "product-module--title--92dc6";
export var topbar = "product-module--topbar--6a933";